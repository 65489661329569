
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { useI18n } from "vue-i18n";
import { salesOrderOverviewFormData } from "@/core/directive/interface/salesOrder";
import { ApiSalesOrders } from "@/core/api";
import { CommonDateType, NumberOrString } from "@/core/directive/type/common";
import { formatDate, formatDateTime } from "@/core/directive/function/common";
import { getOrderProductIdData } from "@/core/directive/function/order";

export default defineComponent({
  name: "mb-order-routing-routing-overview-detail",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();

    const loading = ref(true);
    const OrderAssignOverviewData = ref(
      Object.assign({}, salesOrderOverviewFormData)
    );

    const getShowInfo = async () => {
      const { data } = await ApiSalesOrders.getCompanyInfo({
        order_id: route.params.id,
      });
      if (data.code == 0) {
        if (data.data.sales_order_identifier) {
          OrderAssignOverviewData.value = data.data;
        }
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const customFormatDate = (date: CommonDateType, format = "YYYY.MM.DD") => {
      return formatDate(date, format);
    };

    const isRefundOverview = computed(() => {
      return (
        route.name === "mb-order-routing-routing-overview-refund" ||
        route.name === "mb-order-routing-routing-overview-return-refund"
      );
    });

    onMounted(() => {
      MenuComponent.reinitialization();
      if (!isRefundOverview.value) {
        getFormInfo();
      }
    });

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      OrderAssignOverviewData,
      getFormInfo,
      customFormatDate,
      isRefundOverview,
    };
  },
});
